<template>
  <div>
    <div class="card border rounded">
      <h3 class="text-muted p-4">Configurações</h3>
      <b-row class="px-md-0 px-3">
        <b-col cols="12" md="6">
          <div class="pb-4">
            <b-row class="d-flex justify-content-center align-items-center">
              <b-col cols="12" md="10">
                <label>Juros Mensal (%)</label>
                <b-input-group>
                  <b-form-input
                    @input="formatInterestRate"
                    v-model="settings.monthlyInterestRate"
                    type="text"
                    size="sm"
                    :class="{
                      'is-invalid':
                        submitted &&
                        ($v.settings.monthlyInterestRate.$error ||
                          $v.formattedMonthlyInterestRate.$error),
                    }"
                  />
                  <div
                    v-if="
                      submitted &&
                      ($v.settings.monthlyInterestRate.$error ||
                        $v.formattedMonthlyInterestRate.$error)
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.settings.monthlyInterestRate.required">
                      Campo obrigatório.
                    </span>
                    <span
                      v-else-if="
                        !$v.formattedMonthlyInterestRate.minValue &&
                        !$v.formattedMonthlyInterestRate.maxValue
                      "
                    >
                      Insira apenas números.
                    </span>
                    <span v-else-if="!$v.formattedMonthlyInterestRate.maxValue">
                      Valor muito alto.
                    </span>
                    <span v-else-if="!$v.formattedMonthlyInterestRate.minValue">
                      Insira apenas números positivos.
                    </span>
                  </div>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
              class="d-flex justify-content-center align-items-center mt-3"
            >
              <b-col cols="12" md="10" rows="3">
                <b-form-group label="Mensagem SMS ao Criar Pagamento">
                  <b-form-textarea
                    class="d-block"
                    :class="{
                      'is-invalid':
                        submitted && $v.settings.paymentSmsMessage.$error,
                    }"
                    v-model="settings.paymentSmsMessage"
                  />
                  <div
                    v-if="submitted && $v.settings.paymentSmsMessage.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.settings.paymentSmsMessage.required">
                      Campo obrigatório.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center align-items-center">
              <b-col cols="12" md="10">
                <b-button
                  class="my-3"
                  block
                  variant="light"
                  @click="goToSettingsHistory"
                >
                  Histórico de Config.
                </b-button>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center align-items-center">
              <b-col cols="12" md="10">
                <b-button
                  variant="primary"
                  block
                  class="rounded"
                  @click="registerSettings"
                >
                  Salvar
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="pb-4">
            <b-row class="">
              <b-col cols="12" md="10">
                <label>Tasks</label>
                <b-input-group>
                  <b-form-select v-model="selectedTask" :options="tasks" />
                  <b-input-group-append>
                    <b-button size="sm" variant="primary" @click="runTask">
                      Rodar Task
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            <div class="px-2" v-if="loading || taskData">
              <hr />
              <b-row class="p-3">
                <b-col v-if="loading" cols="12">
                  <b-skeleton></b-skeleton>
                  <b-skeleton width="75%"></b-skeleton>
                  <b-skeleton width="68%"></b-skeleton>
                </b-col>
                <b-col v-else-if="taskData">
                  <span v-for="string in taskData" :key="string">
                    {{ string }} <br />
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import PlgPaymentsApi from "../../apis/PlgPaymentsApi.vue";
import PlgSettingsApi from "../../apis/PlgSettingsApi.vue";
import Toasts from "../../components/Toasts.vue";

export default {
  name: "Settings",
  mixins: [PlgPaymentsApi, PlgSettingsApi, Toasts],
  data() {
    return {
      tasks: [
        {
          text: "Realizar pagamentos agendados",
          value: "scheduledPayments",
        },
      ],
      settings: {
        monthlyInterestRate: "",
        paymentSmsMessage: "",
      },
      settingsApi: {},
      taskData: null,
      loading: false,
      formattedMonthlyInterestRate: 0,
      submitted: false,
      selectedTask: "scheduledPayments",
    };
  },
  validations() {
    return {
      settings: {
        monthlyInterestRate: { required },
        paymentSmsMessage: { required },
      },
      formattedMonthlyInterestRate: {
        minValue: minValue(0.0),
        maxValue: maxValue(100.0),
      },
    };
  },
  methods: {
    formatInterestRate() {
      this.formattedMonthlyInterestRate = parseFloat(
        this.settings.monthlyInterestRate.replace(/,/, ".")
      );
    },
    async registerSettings() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          settings: {
            monthly_interest_rate:
              this.formattedMonthlyInterestRate ||
              this.settings.monthlyInterestRate,
            payment_sms_message: this.settings.paymentSmsMessage,
          },
        };
        const response = await this.registerSettingsApi(payload);
        if (response.status == 200) {
          this.showToast("success", "Configurações registradas com sucesso!");
        } else {
          this.showToast("error", `Erro ao salvar configurações.`);
        }
      }
    },
    async runTask() {
      this.loading = true;
      const response =
        this.selectedTask == "scheduledPayments"
          ? await this.payScheduledPayments()
          : null;
      this.taskData = response?.data.split("\n\n");
      this.taskData = this.taskData.filter((el) => el);
      this.loading = false;
    },
    goToSettingsHistory() {
      this.$router.push({ name: "settingsHistory" });
    },
  },
  async mounted() {
    this.settingsApi = await this.getSettingsApi(true);
    this.settings.monthlyInterestRate =
      this.settingsApi.data.monthly_interest_rate;
    this.settings.paymentSmsMessage = this.settingsApi.data.payment_sms_message;
    this.formatInterestRate();
  },
};
</script>
