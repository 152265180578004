<template>
  <div></div>
</template>

<script>
import axios from "axios";
import { authHeader } from "./authHeader"

export default {
  name: "PlgSettingsApi",
  
  methods: {
    formatError(error) {
      error = { data: { message: error.response.data.detail, status: "error" } };
      return error;
    },
    async registerSettingsApi(payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/settings`,
          payload, authHeader()
        );
        return response;
      } catch (error) {
        return this.formatError(error);
      }
    },
    async getSettingsApi(currentOnly) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/settings?current_only=${currentOnly}`,
          authHeader()
        );
        return response;
      } catch (error) {
        return this.formatError(error);
      }
    }
  }
}
</script>