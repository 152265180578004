<template>
  <div></div>
</template>

<script>
import axios from "axios";
import { authHeader } from "./authHeader"

export default {
  name: "PlgPaymentsApi",
  methods: {
    formatError(error) {
      error = { data: { message: error.response.data.detail, status: "error" } };
      return error;
    },
    getFilterForPaymentsApi() {
      let filter = {
        id: null,
        skip: 0,
        limit: 0,
        order: true,
        approved: [],
        antcipated_amount: null,
        sort: null,
        scheduled_date: null,
        after_date: null,
        amount: null,
        receiver_id: null,
        receiver_federal_id: null,
      }
      return filter;
    },
    async getPaymentsApi(filter, exportXls = false) {
      let response = null;
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/payments/list`,
          filter,
          { params: {export_xls: exportXls}, ...authHeader(exportXls ? "blob" : null) }
        );
        if (exportXls) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "payments-list.xlsx");
          document.body.appendChild(link);
          link.click();
        }
        if (response != null) return response;
      } catch (error) {
        return this.formatError(error);
      }
    },
    async getPaymentApi(id) {
      let response = null;
      let filter = this.getFilterForPaymentsApi();
      filter.id = id;
      const payload = {
        filter: filter
      }
      try {
        response = await this.getPaymentsApi(payload);
      } catch (error) {
        return this.formatError(error);
      }
      return response;
    },
    async createPaymentApi(payload) {
      let response = null;
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/payments/create`,
          payload, authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response
    },
    async updatePaymentApi(paymentId, payload) {
      let response = null;
      try {
        response = await axios.put(
          `${process.env.VUE_APP_API_URL}/payments/update/${paymentId}`,
          payload, authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response
    },
    async payPaymentApi(paymentId, payload) {
      let response = null;
      try {
        response = await axios.put(
          `${process.env.VUE_APP_API_URL}/payments/${process.env.VUE_APP_BANK_API}/pay/${paymentId}`,
          payload, authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response
    },
    async uploadXlsx(file) {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/payments/upload-xlsx`,
            formData,
            authHeader()
          );
        return response
      } catch (error) {
        return this.formatError(error)
      }
    },
    async downloadXlsx() {
      try {
        const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/payments/example-xlsx`,
            authHeader('blob')
          );
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/excel' })
        )
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'exemplo.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
        fileLink.remove()
        return response
      } catch (error) {
        return this.formatError(error)
      }
    },
    async payScheduledPayments() {
      let response = null;
      try {
        response = await axios.get(
          `${process.env.VUE_APP_API_URL}/payments/pay/scheduled`,
          authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response
    },
    async calculateAntecipatedPayment(paymentId) {
      let response = null;
      try {
        response = await axios.get(
          `${process.env.VUE_APP_API_URL}/payments/antecipate?payment_id=${paymentId}`,
          authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response
    },
    async checkForEnoughBalance(paymentId) {
      let response = null;
      try {
        response = await axios.get(
          `${process.env.VUE_APP_API_URL}/payments/balance?payment_id=${paymentId}`,
          authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response
    },
  }
}
</script>